class InstanticRouter.NewTrxWizard
  constructor: (el) ->
    @$el = $(el)
    @backTo = @$el.data("backTo")
    @datasetName = @$el.data("datasetName")
    console.log(@backTo)
    console.log(@datasetName)

    @delegateEvents()

  navigateBack: (e) ->
    console.log("Back")
    this.fullPost(@backTo, @$el.serializeArray())
    e.preventDefault()

  toggleActualFinishedAtFields: () ->
    receiverAccountId = @$el.find("#new_transaction_step1_form_account_id").val() * 1
    actualFinishedAtAccountIds = $("#new_transaction_step1_form_account_id").data("actualFinishedAtAccountIds")

    girtekaActualFinishedAtWrapper = @$el.find("div.new_transaction_step1_form_girteka_actual_finished_at")

    if actualFinishedAtAccountIds.indexOf(receiverAccountId) > -1
      girtekaActualFinishedAtWrapper.removeClass("display-none")
    else
      girtekaActualFinishedAtWrapper.addClass("display-none")

  delegateEvents: ->
    @$el.on 'click', "#new-trx-wizard-back", $.proxy(@navigateBack, @)
    @$el.on 'change', "#new_transaction_step1_form_account_id", $.proxy(@toggleActualFinishedAtFields, @)

  fullPost: (location, args) ->
    console.log(location)

    datasetName = @datasetName

    form = $('<form></form>')
    form.attr 'method', 'post'
    form.attr 'action', location
    $.each args, (index, arg) ->
      newName = arg.name.replace(/^.+\_form\[/, datasetName + "[")
      field = $('<input></input>')
      field.attr 'type', 'hidden'
      field.attr 'name', newName
      field.attr 'value', arg.value
      form.append field
      return

    field = $('<input></input>')
    field.attr 'type', 'hidden'
    field.attr 'name', "back_action"
    field.attr 'value', "1"
    form.append field

    $(form).appendTo('body').submit()
    return
