class InstanticRouter.WebTerminal
  constructor: (el) ->
    @$el = $(el)
    @initializeWebTerminal()
    @delegateEvents()

  initializeWebTerminal: ->
    @enableAndDisableFields()

  enableAndDisableFields: ->
    commandElement = @$el.find("select[name='xmlfields[command]']")
    command = commandElement.val()

    smsCode = @$el.find("input[name='xmlfields[sms_code]']")
    productCode = @$el.find("input[name='xmlfields[product_code_i]']")
    productQuantity = @$el.find("input[name='xmlfields[product_quantity]']")
    price = @$el.find("input[name='xmlfields[price]']")

    if command == "reserve"
      smsCode.removeAttr("disabled")
      productCode.removeAttr("disabled")
      productQuantity.attr("disabled", "disabled")
      price.attr("disabled", "disabled")
    else if command == "close"
      smsCode.removeAttr("disabled")
      productCode.removeAttr("disabled")
      productQuantity.removeAttr("disabled")
      price.attr("disabled", "disabled")
    else if command == "decline"
      smsCode.removeAttr("disabled")
      productCode.attr("disabled", "disabled")
      productQuantity.attr("disabled", "disabled")
      price.attr("disabled", "disabled")
    else if command == "set_price"
      smsCode.attr("disabled", "disabled")
      productCode.removeAttr("disabled")
      productQuantity.attr("disabled", "disabled")
      price.removeAttr("disabled")
    else
      smsCode.attr("disabled", "disabled")
      productCode.attr("disabled", "disabled")
      productQuantity.attr("disabled", "disabled")
      price.attr("disabled", "disabled")

  delegateEvents: ->
    @$el.on 'change', "select[name='xmlfields[command]']", $.proxy(@enableAndDisableFields, @)
