# TODO: solve
# #= require jquery-ui/datepicker
# #= require jquery.maskedinput.min
# #= require jquery.tipsy

window.InstanticRouter = {}

require './components/new_trx_wizard.coffee'
require './components/terminal_operation_tool.coffee'
require './components/web_terminal.coffee'

window.InstanticRouter.load = (scope) ->
  $(scope).find("[data-component]").each ->
    $el = $(@)
    if !$el.data('component-initialized')
      name = $el.data('component')
      if InstanticRouter[name] != undefined
        new InstanticRouter[name]($el)
        $el.attr('data-component-initialized', true)

window.setupMasks = ($inputs) ->
  $inputs.each ->
    mask = $(this).attr('data-mask')
    $(this).mask(mask)

window.setupTipsies = ($elements) ->
  $elements.each ->
    gravity = $(this).attr('data-tipsy')
    html = $(this).attr('data-tipsy-render-html') == "true"
    $(this).tipsy(gravity: gravity, html: html)

window.initSelect2 = (selector) ->
  $.each $(selector), (i, elem) ->
    $elem = $(elem)
    data = $elem.data()

    if data.ajaxUrl
      $elem.select2(
        placeholder: " ", # nice hack, winning (otherwise its impossible to clear selected value)
        initSelection: (element, callback) ->
          callback({id: element.val(), text: element.data().humanValue})
        ajax:
          url: data.ajaxUrl,
          dataType: 'json',
          quietMillis: 350,
          data: (searchTerm, pageNumber, context) ->
            return {
              q: searchTerm,
              page: pageNumber,
            }
          results: (remoteData, pageNumber, query) ->
            return remoteData

        allowClear: true,
        #width: "165px",
        width: 'resolve',
      )

      $elem.on "select2-selecting", (e) ->
        if $elem.prop("id") == "new_transaction_step1_form_terminal_id"
          $("#time_zone").val(e.choice.time_zone)
        else if $elem.prop("id") == "new_terminal_operation_step1_form_terminal_id"
          $("#require_price").val(e.choice.require_price)
    else
      $elem.select2(
        allowClear: true,
        #width: "165px",
        width: 'resolve',
      )

window.initSimpleStateListener = (ssl) ->
  dataNode = $(ssl)
  data = dataNode.data()

  queryPath = data.queryPath
  currentState = data.currentState
  ignoredStates = (data.ignoredStates || "").split(",")
  interval = 3000

  doQuery = () ->
    stopped = false
    $.ajax(
      url: queryPath
      cache: false
      dataType: "json"
    ).done((result) ->
      if result.state != currentState && $.inArray(result.state, ignoredStates) == -1
        stopped = true
        window.location.reload()
    ).always(->
      setTimeout(doQuery, interval) unless stopped
    )

  doQuery()

$ ->
  console.log("jQ ready")

  InstanticRouter.load(document.body)

  window.setupMasks($("[data-mask]"))
  window.initSelect2("select.as-select2")
  window.initSelect2("input.as-select2")
  window.setupTipsies($("[data-tipsy]"))

  $(".advanced-search-button").click (e) ->
    $(".advanced-list-filter").toggle()
    false

  $("input.date").datepicker({
    dateFormat: 'yy-mm-dd',
    onSelect: () ->
      id = this.id
      if id.endsWith('_date')
        timeId = id.replace(/_date$/, "_time")
        timeElement = $("#" + timeId)
        if timeElement.size() == 1
          timeElement.focus()
  })

  $("#simple-state-listener").each (idx, ssl) ->
    window.initSimpleStateListener(ssl)
