class InstanticRouter.TerminalOperationTool
  constructor: (el) ->
    @$el = $(el)
    @initializeTool()
    @delegateEvents()
    @loading = false

  initializeTool: ->
    null

  toggleLoading: (val) ->
    @loading = val

  refreshPrice: ->
    requirePriceElement = @$el.find("#require_price").val()
    requirePrice = requirePriceElement == "true"

    $priceElement = @$el.find("#new_terminal_operation_step1_form_product_price")

    if requirePrice
      console.log("price required")
      config = @$el.data()

      op = @$el.find("#new_terminal_operation_step1_form_operation_code").val()
      terminal_id = @$el.find("#new_terminal_operation_step1_form_terminal_id").val()
      product_type_id = @$el.find("#new_terminal_operation_step1_form_product_type_id").val()

      if op != "" && terminal_id != "" && product_type_id != "" && !@loading
        $priceElement.prop("type", "text")
        $priceElement.val("Loading...")
        $priceElement.prop("readonly", true)

        @toggleLoading(true)

        $.ajax(
          url: config.pricePath
          cache: false
          dataType: "json"
          data:
            op: op
            terminal_id: terminal_id
            product_type_id: product_type_id
        ).done((result) ->
          $priceElement.prop("type", "number")
          $priceElement.val(result.price)
          $priceElement.prop("readonly", false)
        ).fail(->
          $priceElement.prop("type", "number")
          $priceElement.val("-1")
          $priceElement.prop("readonly", false)
        ).always(=>
          @toggleLoading(false)
        )
    else
      console.log("price not required")
      $priceElement.prop("type", "text")
      $priceElement.val("")
      $priceElement.prop("readonly", true)

  toggleFields: ->
    requirePriceElement = @$el.find("#require_price").val()
    requirePrice = requirePriceElement == "true"

    commandElement = @$el.find("#new_terminal_operation_step1_form_operation_code")
    command = commandElement.val()
    console.log(command)

    productTypeWrapper = @$el.find("div.new_terminal_operation_step1_form_product_type_id")
    productQuantityWrapper = @$el.find("div.new_terminal_operation_step1_form_product_quantity")
    productPriceWrapper = @$el.find("div.new_terminal_operation_step1_form_product_price")

    if command == "reserve"
      productTypeWrapper.removeClass("display-none")
      productQuantityWrapper.addClass("display-none")
      if requirePrice
        productPriceWrapper.removeClass("display-none")
      else
        productPriceWrapper.addClass("display-none")
    else if command == "close"
      productTypeWrapper.removeClass("display-none")
      productQuantityWrapper.removeClass("display-none")
      productPriceWrapper.addClass("display-none")
    else if command == "reserve_and_close"
      productTypeWrapper.removeClass("display-none")
      productQuantityWrapper.removeClass("display-none")
      if requirePrice
        productPriceWrapper.removeClass("display-none")
      else
        productPriceWrapper.addClass("display-none")
    else if command == "decline"
      productTypeWrapper.addClass("display-none")
      productQuantityWrapper.addClass("display-none")
      productPriceWrapper.addClass("display-none")
    else
      productTypeWrapper.addClass("display-none")
      productQuantityWrapper.addClass("display-none")
      productPriceWrapper.addClass("display-none")

  delegateEvents: ->
    @$el.on 'change', "#new_terminal_operation_step1_form_operation_code", $.proxy(@toggleFields, @)
    @$el.on 'change', "#new_terminal_operation_step1_form_terminal_id", $.proxy(@toggleFields, @)

    @$el.on 'change', "#new_terminal_operation_step1_form_operation_code", $.proxy(@refreshPrice, @)
    @$el.on 'change', "#new_terminal_operation_step1_form_product_type_id", $.proxy(@refreshPrice, @)
    @$el.on 'change', "#new_terminal_operation_step1_form_terminal_id", $.proxy(@refreshPrice, @)
