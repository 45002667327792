import jQuery from "jquery";

// TODO: expose-loader
window.$ = jQuery
window.jQuery = jQuery

console.log('..:: Instantic Router ::..')

require("rails-ujs").start();

require('bootstrap-sass/js/bootstrap-tab')
require('bootstrap-sass/js/bootstrap-dropdown')

require('select2/select2')
require('../vendor/jquery.tipsy.js')
require('../vendor/jquery.maskedinput.min.js')

require("jquery-ui/ui/widgets/datepicker");

require('RailsJS/admin.original.coffee')
